// ** React Imports
import {Suspense, lazy} from 'react'
import ReactDOM from 'react-dom/client';

// ** Redux Imports
import {Provider} from 'react-redux'
import {store} from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import {ToastContainer} from 'react-toastify'
import {ThemeContext} from './utility/context/ThemeColors'
import {IntlProviderWrapper} from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from './components/spinner/Fallback-spinner'

// ** Ripple Button
import './components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import '@assets/fonts/feather/iconfont.css'
import '@styles/core.scss'
import '@styles/style.scss'
import "@styles/panel.scss"
import Jq from "jquery";

window.$ = Jq;

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner/>}>
            <ThemeContext>
                <IntlProviderWrapper>
                    <LazyApp/>
                    <ToastContainer newestOnTop/>
                </IntlProviderWrapper>
            </ThemeContext>
        </Suspense>
    </Provider>,
);

// ** React Imports
import {useState, createContext} from 'react'

// ** Intl Provider Import
import {IntlProvider} from 'react-intl'

import messagesEn from '@assets/data/locales/en.json'
import messagesIr from '@assets/data/locales/ir.json'

// ** Menu msg obj
const menuMessages = {
    en: {...messagesEn},
    ir: {...messagesIr}
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({children}) => {
    // ** States
    const [locale, setLocale] = useState('ir')
    const [messages, setMessages] = useState(menuMessages['ir'])

    // ** Switches Language
    const switchLanguage = lang => {
        setLocale(lang)
        setMessages(menuMessages[lang])
    }

    return (
        <Context.Provider value={{locale, switchLanguage}}>
            <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='ir'>
                {children}
            </IntlProvider>
        </Context.Provider>
    )
}

export {IntlProviderWrapper, Context as IntlContext}

const initialState = {
    articles: []
}

const UIReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UI':
            return {
                ...state,
                sliders: action.data ? action.data.sliders : [],
                brands: action.data ? action.data.brands : [],
                news: action.data ? action.data.news : null,
                aboutVideos: action.data ? action.data.about_videos : [],
                achievementVideos: action.data ? action.data.achievement_videos : [],
                gallery: action.data ? action.data.gallery : [],
                FAQs: action.data ? action.data.FAQs : [],
                articles: action.data ? action.data.articles.data : []
            }
        default:
            return state
    }
}

export default UIReducer
